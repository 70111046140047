<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <div class="col-md-auto me-0 ms-auto me-2" v-if="total > 0 && permission.isExportJobProgressReportData">
          <a
            href="javascript:void(0);"
            class="btn btn-gray"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            ><i class="bi bi-file-earmark-arrow-down fs-4"></i> {{ constants.report.EXPORT_JOB_PROGRESS }}
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
            </span>
          </a>
          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-gray fw-bold fs-7 w-250px py-4" data-kt-menu="true">
            <div class="menu-item px-3">
              <a @click="exportJobProgressReportData('xlsx')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.report.JOB_PROGRESS_EXCEL_EXPORT }}</a>
            </div>
            <div class="menu-item px-3">
              <a @click="exportJobProgressReportData('csv')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.report.JOB_PROGRESS_CSV_EXPORT }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-6">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <template v-slot:cell-product_name="{ row: data }">
          {{ data.product_name != null ? data.product_name : '-' }}
        </template>
        <template v-slot:cell-product_sku="{ row: data }">
          {{ data.product_sku }}
        </template>
        <template v-slot:cell-order_product_status="{ row: data }">
          {{ data.order_product_status.title }}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatPrice, formatDate, formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { notificationFire } from "@/composable/notification.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default {
  name: "job-progress-report",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const searchParams = reactive({
      recordsPerPage: Number(JSON.parse(window.localStorage.getItem('site_config'))['per_page']),
      pageNub: 1,
      partner:"",
      search:"",
      sortBy:"",
      sortDesc:"",
      type:"listing"
    })

    const permission = reactive({
      isViewJobProgressReportAllowed: false,
      isExportJobProgressReportData: false
    })

    const tableHeader = reactive([
      {
        name: 'Order Product Name',
        key: 'product_name',
        width: "w-200px",
        visible: true,
        alignment: 'text-start',
        sortable: false,
      },
      {
        name: 'Order Product SKU',
        key: "product_sku",
        sortable: true,
        width: "w-10px",
        visible: true,
        alignment: 'text-start'
      },
      {
        name: 'Order Product Status',
        key: 'order_product_status',
        width: "w-100px",
        visible: true,
        alignment: 'text-center'
      },
    ]);
    
    const handleSizeChange = (number) => {
      searchParams.recordsPerPage = number;
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getJobProgressData();
    }
    
    const handleCurrentChange = (number) => {
      searchParams.pageNub = number;
      getJobProgressData();
    }

    //Get all job progress data
    const getJobProgressData = async () => {
      loading.value = true;
      searchParams.type = "listing";
      await ApiService.post("reports/job-progress", { params: searchParams })
      .then(({ data }) => {
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
          tableData.value = [];
          total.value = 0;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      getJobProgressData();
    };

    //Order search event function
    const handleSearch = () => {
      handleCurrentChange(1);
    }

    //Export job progress summery data
    const exportJobProgressReportData = (extension) => {
      searchParams.type = "download";
      axios({
        url: 'reports/job-progress',
        method: 'POST',
        data: {
          params: searchParams,
          extension: extension
        },
        responseType: 'arraybuffer',
      }).then((response) => {
        let blob = new Blob([response.data], {
            type: ''
        })
        let link = document.createElement('a')
        link.setAttribute('target','_blank')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'job_progress_'+Date.now()+'.'+extension
        link.click()
      })
      .catch((error) => {
        let message = error.message;
        if (message != "") {
          notificationFire(message, "error");
        }
      });
      return false;
    }

    //Set breadcrumbs and get the job progress data for report
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("ViewJobProgressReport") || role == "Super Admin")
        permission.isViewJobProgressReportAllowed = true;
      if (per.includes("ExportJobProgressReportData") || role == "Super Admin")
        permission.isExportJobProgressReportData = true;
      await getJobProgressData();
      MenuComponent.reinitialization();
    })
    
    return {
      tableData,
      formatPrice,
      formatDate,
      getJobProgressData,
      total,
      handleSizeChange,
      handleCurrentChange,
      handleSortChange,
      loading,
      searchParams,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      permission,
      exportJobProgressReportData,
      constants
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
// For Export Button
.btn.btn-gray{
  background-color: #858585;
  border-color: #858585;
  color:#ffffff;
}
.btn.btn-gray i{color:#ffffff;}
.btn.btn-gray svg path{fill:#ffffff !important;}
.btn.btn-gray:hover,.btn.btn-gray:focus,.btn.btn-gray:active{
  background-color: #666666;
  border-color: #666666;
}
.menu-state-bg-light-gray .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-gray .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f3f3f3;
  color: #858585;
}
</style>